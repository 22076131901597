.swiper-pagination-bullet {
  border-radius: 0 !important;
  transform: rotate(45deg);
  background: #DCE0E9 !important;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 6px) !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.4) !important;
}

.swiper-pagination-bullet-active {
  @apply bg-yellow #{!important};
  border: 0.4px solid #FFFFFF;
  box-shadow: 0 0 10px rgba(252, 238, 33, 0.6);
  border-radius: 1px;
  height: 11px !important;
  width: 11px !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
}

.swiper-pagination {
  @apply flex items-center justify-center;
}

.image-bottom-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.04%, rgba(0, 0, 0, 0.11875) 71.9%, rgba(0, 0, 0, 0.25625) 85.43%, rgba(0, 0, 0, 0.4) 100%)
}
