.btn,
.btn-sm {
  @apply inline-flex items-center justify-center border border-transparent rounded-xl leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-2 py-1.5 xl:px-4 xl:py-3 shadow-lg text-white;
  border: 1px solid #333333;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}

.btn-primary {
  @apply bg-gradient-to-r from-red to-orange text-white;
  box-shadow: 0 4px 30px rgba(252, 66, 14, 0.4);
}

.btn-text-orange {
  @apply text-orange;
}

.btn-orange {
  background: rgba(247, 147, 30, 0.2);
  border: 1px solid #F7931E;
}

.btn-black {
  @apply bg-semi-black p-2 rounded-md text-white text-sm flex items-center;
  img {
    @apply mr-2;
  }
}

button:hover,
button:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(#fff,0);
}
