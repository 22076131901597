.gaming-summary {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(
                  97.09deg, #1B1E20 1.51%, #1A1C20 88.87%);
  div:not(:last-child) {
    @apply border-r border-gray;
  }
}

.image-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.03%, rgba(0, 0, 0, 0.3) 85.83%);
}

.thumb-swiper {
  .swiper-slide {
    opacity: 0.4;
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.filter-container {
  background: linear-gradient(97.09deg, #1B1E20 1.51%, #1A1C20 88.87%);
}
