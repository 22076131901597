input {
  @apply bg-black rounded-xl text-white border border-light-gray py-1.5 px-3 xl:py-3;
}

.input-suffix {
  @apply relative;
  input {
    @apply pr-10 w-full;
  }
  .icon-suffix {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0.75rem;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      @apply h-6 w-6;
    }
  }
}