.shadow-left {
  background: linear-gradient(96deg, #131416 12.57%, rgba(19, 20, 22, 0) 84.25%);
}

.shadow-right {
  background: linear-gradient(258deg, #131416 12.57%, rgba(19, 20, 22, 0) 84.25%);
}

.translate-left-1 {
  transform: translateX(-54px);
}

.translate-left-2 {
  transform: translateX(-179px);
}

@media (max-width: 767px) {
  .shadow-left,
  .shadow-right {
    background: none;
  }

  .translate-left-1 {
    transform: translateX(-105px);
  }

  .translate-left-2 {
    transform: translateX(-178px);
  }
}
