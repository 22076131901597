.rc-collapse {
  background-color: transparent;
  border: none;
  .rc-collapse-item {
    border-top: none;
    .rc-collapse-header {
      @apply font-bold text-white rounded-xl mb-4 p-3 relative;
      background: #272B2F;
      .collapse-ic {
        @apply absolute right-5 left-auto;
      }
    }
  }
}

.rc-collapse-content {
  background-color: transparent;
  color: inherit;
  .rc-collapse-content-box {
    margin-top: 0;
  }
}
