@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~rc-collapse/assets/index.css";

@import "styles/gradient";
@import "styles/variables";
@import "styles/select";
@import "styles/button";
@import "styles/footer";
@import "styles/home";
@import "styles/filter";
@import "styles/explore-card";
@import "styles/live-auction";
@import "styles/swiper-pagination";
@import "styles/top-hero";
@import "styles/header";
@import "styles/input";
@import "styles/check-box";
@import "styles/collapse";
@import "styles/gaming-description";
@import "styles/top-store";
@import "styles/business";
@import "styles/saying-about-us";
@import "styles/resource";

html,
body {
  @apply text-base;
  font-family: $font-main, sans-serf;
  line-height: 32px;
  margin: 0;
}
