.business-circle {
  @apply rounded-full absolute;
  top: -75px;
  left: -75px;
  width: 344px;
  height: 344px;
  opacity: 0.2;
  border: 20px solid #752222;
}

.business-circle-gradient {
  @apply rounded-full absolute;
  right: -272px;
  bottom: -286px;
  width: 960px;
  height: 960px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(168, 11, 0, 0.3) 0%, rgba(19, 20, 22, 0) 100%);
}

.business-container {
  @apply px-5 lg:px-10 py-6 lg:py-14 relative;
  background: linear-gradient(113.31deg, #1f2023 1.87%, #16171a 91.44%);
  border-radius: 2rem;
}

@media (max-width: 767px) {
  .business-circle {
    top: auto;
    left: -100px;
    bottom: -90px;
    width: 214px;
    height: 214px;
  }

  .business-circle-gradient {
    right: auto;
    left: 45px;
    top: -452px;
    bottom: auto;
  }

  .business-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 1rem;
  }
}
